const ROUTES = {
  SIGN_IN: {
    name: 'SignOut',
    route: '/signin',
  },
  HOME: {
    name: '',
    route: '/',
  },
  CRM: {
    name: 'Cascade Advertisers',
    title: 'Cascade Advertisers',
    route: '/crm',
  },
  PUSH: {
    name: 'Push',
    title: 'Push notification',
    route: '/push',
  },
  ANALYTICS: {
    title: "Cascade reports",
    submenu: [
      {
        name: 'Analytics',
        title: 'Analytics',
        route: '/analytics',
      },
      {
        name: 'Rejects',
        title: 'Rejects',
        route: '/rejects',
      },
      {
        name: 'Domains',
        title: 'Domains',
        route: '/domains',
      },
    ],
  },
  TRACKER_REPORT: {
    name: 'Tracker Report',
    title: 'Tracker Report',
    route: '/tracker',
  },
  NO_ACCESS: {
    name: 'No Access',
    route: '/restricted',
  },
};
export default ROUTES;
