import showToast from '../../components/global/Toast';
import axios from '../../config/backoffice';
import { getToken } from '../../helpers/token';

export const fetchClients = async (rejectWithValue) => {
  const token = getToken();

  try {
    const clients = await axios.get('clients/clients', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return clients.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const fetchClientByID = async (id, { rejectWithValue }) => {
  const token = getToken();

  try {
    const client = await axios.get(`clients/client/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return client.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const modifyClient = async (clientInfo, { rejectWithValue, getState }) => {
  const token = getToken();

  const client = getState().clients.clients.client;

  if (clientInfo.hasOwnProperty('payout')) {
    clientInfo.payout = +clientInfo.payout;
  }
  if (clientInfo.hasOwnProperty('priority')) {
    clientInfo.priority = +clientInfo.priority;
  }

  try {
    const { data } = await axios.put(
      `clients/client/${client.id}`,
      { ...clientInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Client was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const removeBlock = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { toDelete } = getState().clients.blocks;

  try {
    const { data } = await axios.delete(`clients/s1block/${toDelete}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'source block was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const message = error.response.data.error;
      const status = error.response.data.status;
      return rejectWithValue({ status, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const removeClientBlock = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { selectedBlock } = getState().clients.clients;
  const client = getState().clients.clients.client;

  try {
    const { data } = await axios.delete(`clients/client/${client.id}/s1block/${selectedBlock}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'source block was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.error || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const fetchSourcesWithPriority = async (rejectWithValue) => {
  const token = getToken();

  try {
    const blocks = await axios.get('clients/sources-priority', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return blocks.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const modifySource = async (sourceInfo, { rejectWithValue, getState }) => {
  const token = getToken();

  try {
    const { data } = await axios.put(
      `clients/source/${sourceInfo.id}`,
      { ...sourceInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Source was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const fetchSources = async (rejectWithValue) => {
  const token = getToken();

  try {
    const sources = await axios.get(`clients/sources`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return sources.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const fetchSource = async (id, { rejectWithValue }) => {
  const token = getToken();
  try {
    const sources = await axios.get(`clients/source/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return sources.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const removeSourcePriority = async (id, { rejectWithValue }) => {
  const token = getToken();
  try {
    const sources = await axios.delete(`clients/source/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return sources.data;
  } catch (error) {
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const createSourcePriority = async (data, { rejectWithValue }) => {
  const token = getToken();
  try {
    const sources = await axios.post(
      `clients/source`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Custom priority was created',
    });
    return sources.data;
  } catch (error) {
    console.log(error);
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else {
      const { status, message } = error;
      return rejectWithValue({ status, message });
    }
  }
};

export const addBlocksToClient = async (blockInfo, { rejectWithValue, getState }) => {
  const token = getToken();
  const client = getState().clients.clients.client;
  var blocks = {
    sources: blockInfo,
  };

  try {
    const { data } = await axios.post(`clients/client-s1block/${client.id}`, blocks, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Added successfully',
      description: 'Source block was added to the client!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const createSourceBlock = async (blockInfo, { rejectWithValue }) => {
  const token = getToken();
  var block = {
    value: blockInfo.block_name,
  };

  try {
    const { data } = await axios.post(`clients/s1block`, block, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Source block was created!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const createLimit = async (limitInfo, { rejectWithValue, getState }) => {
  const token = getToken();
  const { id } = getState().clients.clients.client;

  try {
    const { data } = await axios.post(
      `clients/limit`,
      { client_id: id, ...limitInfo },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Created successfully',
      description: 'Limit was created!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { message } = error.response.data;
      return rejectWithValue({ message });
    } else {
      const { message } = error;
      return rejectWithValue({ message });
    }
  }
};

export const removeLimit = async ({ rejectWithValue, getState }) => {
  const token = getToken();
  const { selectedLimit } = getState().clients.clients;

  try {
    const { data } = await axios.delete(`clients/limit/${selectedLimit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    showToast({
      type: 'success',
      title: 'Deleted successfully',
      description: 'limit was deleted!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { code, message } = error.response.data;
      return rejectWithValue({ code, message });
    } else {
      const { code, message } = error;
      return rejectWithValue({ code, message });
    }
  }
};

export const updateLimitByID = async (limitInfo, { rejectWithValue, getState }) => {
  const token = getToken();
  const { selectedLimit } = getState().clients.clients;
  var { value, value_type } = limitInfo;
  try {
    const { data } = await axios.put(
      `clients/limit/${selectedLimit}`,
      { value: Number(value), value_type: value_type },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showToast({
      type: 'success',
      title: 'Updated successfully',
      description: 'Limit was updated!',
    });
    return { status: data };
  } catch (error) {
    showToast({
      type: 'error',
      title: 'Server error',
      description: error.response?.data.message || error?.message || error || 'Please try later',
    });
    if (error.response) {
      const { message } = error.response.data;
      return rejectWithValue({ message });
    } else {
      const { message } = error;
      return rejectWithValue({ message });
    }
  }
};
