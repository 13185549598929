import React from 'react';

const CreateButton = ({createFunc}) => {
  const handleClick = () => {
    createFunc();
  };
  return (
    <button
      onClick={() => handleClick()}
      className='border py-2 px-4 rounded-md bg-transparent border-main-grey-2 dark:border-main-black-2 text-main-black dark:text-main-grey-2'
    >
      Create message
    </button>
  );
};

export default CreateButton;
