import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import errorSelector from '../../state/tracker-report/selectors/error';
import loadingSelector from '../../state/tracker-report/selectors/loading';
import sidebarMenuSelector from '../../state/menus/selectors/sidebar';
import trackerReportSelector from '../../state/tracker-report/selectors/report';
import { setTrackerReport } from '../../state/tracker-report/async-actions';
import Page401 from '../error-pages/Page401';
import Page403 from '../error-pages/Page403';
import Loading from '../global/Loading';
import classNames from 'classnames';
import FiltersRow from './FiltersRow';
import trackerFiltersSelector from '../../state/tracker-report/selectors/filter/filters';
import Table from './table/Table';

const Tracker = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const loading = useSelector(loadingSelector);
  const sidebar = useSelector(sidebarMenuSelector);
  let report = useSelector(trackerReportSelector);
  const {selectedDate, source} = useSelector(trackerFiltersSelector);

  useEffect(() => {
    dispatch(setTrackerReport({ selectedDate, source }));
  }, [dispatch, selectedDate, source]);

  report = report.filter(item => item.Total.name.toLowerCase().includes(source.toLowerCase()))

  if (error?.status === 401) {
    return <Page401 service='dashboard' error={error} />;
  }
  if (error?.status === 403) {
    return <Page403 />;
  }
  if (error && error.status !== 404) {
    return <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center my-0 m-auto'>{error.message}</h2>;
  }
  if (loading) return <Loading />;
  return (
    <section
      className={classNames('flex flex-col px-[30px] max-md:px-[10px] pt-8  pb-[100px] overflow-x-auto', {
        'min-w-[calc(100vw-128px-60px)]': !sidebar,
        'min-w-[calc(100vw-238px-60px)]': sidebar,
      })}
    >
      <FiltersRow />
      {Object.keys(report).length === 0 ? (
        <h2 className='text-black-1 dark:text-white text-3xl pt-10 text-center'>Not found</h2>
      ) : (
        <Table report={report}/>
      )}
    </section>
  );
}

export default Tracker