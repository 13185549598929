import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addBlock: false,
  addLang: false,
  addLimit: false,
  createMessage: false,
  createBlock: false,
  messageImage: false,
  updateLimit: false,
  sourceConfig: false,
  showAdvertisers: false,
  deleteBlockConf: false,
  deleteAppConf: false,
  deleteMessageConf: false,
  updateClientConf: false,
  updateAppConf: false,
  updateMessageConf: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleAddBlockModal(state) {
      state.addBlock = !state.addBlock;
    },
    toggleSourceConfigModal(state) {
      state.sourceConfig = !state.sourceConfig;
    },
    toggleCreateBlockModal(state) {
      state.createBlock = !state.createBlock;
    },
    toggleCreateMessageModal(state) {
      state.createMessage = !state.createMessage;
    },
    toggleMessageImageModal(state) {
      state.messageImage = !state.messageImage;
    },
    toggleUpdateLimitModal(state) {
      state.updateLimit = !state.updateLimit;
    },
    toggleAddLangModal(state) {
      state.addLang = !state.addLang;
    },
    toggleShowAdvertisersModal(state) {
      state.showAdvertisers = !state.showAdvertisers;
    },
    toggleAddLimitModal(state) {
      state.addLimit = !state.addLimit;
    },
    toggleDeleteBlockConfModal(state) {
      state.deleteBlockConf = !state.deleteBlockConf;
    },
    toggleDeleteAppConfModal(state) {
      state.deleteAppConf = !state.deleteAppConf;
    },
    toggleDeleteMsgConfModal(state) {
      state.deleteMessageConf = !state.deleteMessageConf;
    },
    toggleUpdateClientConfModal(state) {
      state.updateClientConf = !state.updateClientConf;
    },
    toggleUpdateAppConfModal(state) {
      state.updateAppConf = !state.updateAppConf;
    },
    toggleUpdateMessageConfModal(state) {
      state.updateMessageConf = !state.updateMessageConf;
    },
    resetModals: () => initialState,
  },
});

const modalsReducer = modalsSlice.reducer

export default modalsReducer;
