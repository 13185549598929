import { createSlice } from '@reduxjs/toolkit';
import { PREV_DATE, TODAY_DATE } from '../../constants/date';
import { formatDate } from '../../helpers/time';
import { downloadTrackerReport, setTrackerReport } from './async-actions';

const initialState = {
  report: [],
  pageInfo: {
    totalElements: null,
    totalPages: null,
    pageSize: localStorage.getItem('trackerReportTablePageLimit') || '10',
    currentPage: 1,
  },
  filter: {
    source: '',
    selectedDate: {
      startDate: PREV_DATE,
      endDate: PREV_DATE,
    },
  },
  loading: false,
  error: '',
};

export const trackerStatsSlice = createSlice({
  name: 'tracker-report',
  initialState,
  reducers: {
    setDateRange: (state, { payload }) => {
      state.filter.selectedDate.startDate = payload.startDate ? formatDate(payload.startDate) : PREV_DATE;
      state.filter.selectedDate.endDate = payload.endDate ? formatDate(payload.endDate) : PREV_DATE;
    },
    setCurrentPage: (state, { payload }) => {
      state.pageInfo.currentPage = payload;
    },
    setForcedPage: (state, { payload }) => {
      state.pageInfo.forcedPage = payload;
    },
    setPageLimit: (state, { payload }) => {
      state.pageInfo.pageSize = payload;
      localStorage.setItem('trackerReportTablePageLimit', payload);
      state.pageInfo.totalPages = Math.ceil(state.report.length / payload);
      state.pageInfo.currentPage = 1;
    },
    selectSource: (state, { payload }) => {
      state.filter.source = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTrackerReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.report = payload ? payload : [];
        state.pageInfo.totalElements = state.report.length;
        state.pageInfo.totalPages = Math.ceil(state.report.length / state.pageInfo.pageSize);
      })
      .addCase(setTrackerReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setTrackerReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(downloadTrackerReport.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(downloadTrackerReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadTrackerReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const tracketStatsReducer = trackerStatsSlice.reducer;
export default tracketStatsReducer;
