import userReducer from './user/slice';
import menusReducer from './menus/slice';
import { configureStore } from '@reduxjs/toolkit';
import notificationsReducer from './notifications/slice';
import clientsReducer from './client-management/slice';
import modalsReducer from './modals/slice';
import analyticReducer from './analytics/slice';
import domainsReportReducer from './domains-report/slice';
import pushReducer from './push/slice';
import tracketStatsReducer from './tracker-report/slice';
import rejectsReportReducer from './rejects-report/slice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    menus: menusReducer,
    notifications: notificationsReducer,
    clients: clientsReducer,
    analytic: analyticReducer,
    domainsReport: domainsReportReducer,
    modals: modalsReducer,
    push: pushReducer,
    trackerStats: tracketStatsReducer,
    rejectsReport: rejectsReportReducer
  },
});
