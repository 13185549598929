import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import ROUTES from '../constants/routes';
import { useSelector } from 'react-redux';
import HomePage from '../pages/HomePage';
import AuthenticatedHeader from '../components/global/header/Authenticated';
import SidebarMenu from '../components/global/menus/SidebarMenu';
import { meSelector } from '../state/user/selectors/self';
import Page404 from '../components/error-pages/Page404';
import Client from '../components/crm/clients/client/Client';
import CRMPage from '../pages/CRMPage';
import Clients from '../components/crm/clients/Clients';
import AnalyticPage from '../pages/AnalyticsPage';
import DailyReport from '../components/analytics/daily/Daily';
import AdvertisersReport from '../components/analytics/advertisers/Advertisers';
import SourceReport from '../components/analytics/source/Source';
import { ADVERTISER_REPORT, COUNTRY_REPORT, DAILY_REPORT, LANDING_REPORT, SOURCE_REPORT } from '../constants/reports';
import CountryReport from '../components/analytics/country/Country';
import LandingReport from '../components/analytics/landing/Landing';
import DomainsReportPage from '../pages/DomainsReportPage';
import PushPage from '../pages/PushPage';
import Apps from '../components/push/apps/Apps';
import App from '../components/push/apps/app/App';
import Messages from '../components/push/messages/Messages';
import Message from '../components/push/messages/message/Message';
import TrackerPage from '../pages/TrackerPage';
import RejectsReportPage from '../pages/RejectsReportPage';
import Sources from '../components/crm/sources/sources';

const AuthRouter = () => {
  const { role } = useSelector(meSelector);

  return (
    <BrowserRouter>
      <AuthenticatedHeader />
      <SidebarMenu />
      <Routes>
        <Route path={ROUTES.HOME.route} element={<HomePage />} />
        {role === 'admin' && (
          <>
            {/* CRM page */}
            <Route path={`${ROUTES.CRM.route}/*`} element={<CRMPage />}>
              <Route index element={<Navigate to='clients' replace />} />
              <Route path={`clients/*`} element={<Outlet />}>
                <Route index element={<Clients />} />
                <Route path=':id' element={<Client />} />
              </Route>
              <Route path={`sources/*`} element={<Outlet />}>
                <Route index element={<Sources />} />
              </Route>
            </Route>

            {/* Push page */}
            <Route path={`${ROUTES.PUSH.route}/*`} element={<PushPage />}>
              <Route index element={<Navigate to='apps' replace />} />
              <Route path={`apps/*`} element={<Outlet />}>
                <Route index element={<Apps />} />
                <Route path=':id' element={<App />} />
              </Route>
              <Route path={`messages/*`} element={<Outlet />}>
                <Route index element={<Messages />} />
                <Route path=':id' element={<Message />} />
              </Route>
            </Route>

            {/* AnalyticPage */}
            <Route path={`${ROUTES.ANALYTICS.submenu[0].route}/*`} element={<AnalyticPage />}>
              <Route index element={<Navigate to='daily' replace />} />
              <Route path={`daily/*`} element={<Outlet />}>
                <Route index element={<DailyReport type={DAILY_REPORT} />} />
              </Route>
              <Route path={`advertisers/*`} element={<Outlet />}>
                <Route index element={<AdvertisersReport type={ADVERTISER_REPORT} />} />
              </Route>
              <Route path={`source/*`} element={<Outlet />}>
                <Route index element={<SourceReport type={SOURCE_REPORT} />} />
              </Route>
              <Route path={`country/*`} element={<Outlet />}>
                <Route index element={<CountryReport type={COUNTRY_REPORT} />} />
              </Route>
              <Route path={`landing/*`} element={<Outlet />}>
                <Route index element={<LandingReport type={LANDING_REPORT} />} />
              </Route>
            </Route>
            {/* Domains report page */}
            <Route path={`${ROUTES.ANALYTICS.submenu[2].route}/*`} element={<DomainsReportPage />}/>
            {/* Куосеы report page */}
            <Route path={`${ROUTES.ANALYTICS.submenu[1].route}/*`} element={<RejectsReportPage />}/>
            {/* Tracker report page */}
            <Route path={`${ROUTES.TRACKER_REPORT.route}/*`} element={<TrackerPage />}/>
          </>
        )}
        <Route path={'/*'} element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthRouter;
