import axios from '../../config/backoffice';
import { getToken } from '../../helpers/token';

export const fetchReport = async (
  {
    isDownload,
    isFilter,
    type,
    selectedDate,
    selectedAdvertisers,
    selectedSource,
    selectedCountry,
    selectedLandingID,
    selectedUserEmail,
    sort,
    pageSize,
    currentPage,
  },
  { rejectWithValue }
) => {
  if (isFilter === true) {
    currentPage = 1;
  }
  const token = getToken();
  try {
    const result = await axios.get(
      `reports/${isDownload ? 'download' : 'report'}?` +
        new URLSearchParams({
          type: type,
        }) +
        '&' +
        new URLSearchParams({
          page: currentPage,
        }) +
        '&' +
        new URLSearchParams({
          limit: pageSize,
        }) +
        (selectedDate
          ? '&' +
            new URLSearchParams({
              date: selectedDate.startDate + ',' + selectedDate.endDate,
            })
          : '') +
        (selectedAdvertisers.length > 0
          ? '&' +
            new URLSearchParams({
              advertisers: selectedAdvertisers,
            })
          : '') +
        (selectedSource.length > 0
          ? '&' +
            new URLSearchParams({
              source: selectedSource,
            })
          : '') +
        (selectedCountry.length > 0
          ? '&' +
            new URLSearchParams({
              country: selectedCountry,
            })
          : '') +
        (selectedLandingID.length > 0
          ? '&' +
            new URLSearchParams({
              landing_id: selectedLandingID,
            })
          : '') +
        (selectedUserEmail !== ''
          ? '&' +
            new URLSearchParams({
              user_email: selectedUserEmail,
            })
          : '') +
        (sort.field !== ''
          ? '&' +
            new URLSearchParams({
              sort: sort.field + ',' + sort.sortOpt,
            })
          : ''),
      {
       responseType: isDownload && 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (isDownload) {
      const filename = "report.xlsx"

     // Create download link
     const url = window.URL.createObjectURL(new Blob([result.data]));
     const a = document.createElement('a');
     a.href = url;
     a.download = filename;
     document.body.appendChild(a);
     a.click();
     window.URL.revokeObjectURL(url);
     document.body.removeChild(a);
    } else {
      return await result.data;
    }
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else if (error.request) {
      throw error.request;
    } else {
      console.log('Error', error.message);
    }
  }
};

export const fetchFilters = async (rejectWithValue) => {
  const token = getToken();
  try {
    const result = await axios.get('reports/filters', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await result.data;
  } catch (error) {
    if (error.response) {
      const { status, message } = error.response.data;
      return rejectWithValue({ status, message });
    } else if (error.request) {
      const status = error.request.status;
      const message = error.request.statusText;
      return rejectWithValue({ status, message });
    } else {
      console.log('Error', error.message);
    }
  }
};
